import React from "react";

export const renderHollrSent = function (decodedData: any, compactView: boolean, hollrsLogo: string) {
  return (
    <div>
      <p>
        We have taken care of your hollr so you can take a look later.
      </p>
      <p>
        See you soon!
      </p>
      <div>
        <a href="https://apps.apple.com/gb/app/hollrs/id1668327933" target="_blank">
          <img
            src="/images/Download_on_the_App_Store.svg"
            style={{width: "180px"}}
            alt="Download on the App Store"
          />
        </a>
      </div>
      <div style={{paddingTop: "10px"}}>
        <a href="https://play.google.com/store/apps/details?id=com.hollrs.app" target="_blank">
          <img
            src="/images/google-play-badge.png"
            style={{
              width: "210px"
            }}
            alt="Download on Google Play"
          />
        </a>
      </div>
    </div>
  );
}
